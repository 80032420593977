import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  connect() {
  }

  toggle(event) {
    const clicked = event.currentTarget
    if (clicked.checked) {
      this.checkboxTargets.forEach(other => {
        if (other !== clicked) {
          other.checked = false
        }
      })
    }
  }
}
