import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const submissionQuote = this.element;

    if (submissionQuote) {
      submissionQuote.addEventListener('turbo:submit-end', (e) => {
        const status = e.detail.fetchResponse.response.status;

        if(status === 200){
          const sessionId = this.element.dataset.sessionId;

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "generate_lead",
            session_id: sessionId
          });
        }
      });
    }
  }
}
