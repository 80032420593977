import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle-visibility-on-value"
export default class extends Controller {
  static targets = ["sourceInputs", "targetElements"];

  sourceInputsTargetConnected(target) {
    if (!target.dataset.toggleVisibilityOnValueScope) {
      console.error("Error: data-toggle-visibility-on-value-scope attribute is missing on element", target);
      return;
    }

    target.addEventListener("change", () => this.toggleVisibility(target));
    target.addEventListener("input", () => this.toggleVisibility(target));
  }

  targetElementsTargetConnected(target) {
    const scope = target.dataset.toggleVisibilityOnValueScope;
    const property = target.dataset.toggleVisibilityOnValueClass;

    if (!scope) {
      console.error("Error: data-toggle-visibility-on-value-scope attribute is missing on element", target);
      return;
    }

    if (!property) {
      console.error("Error: data-toggle-visibility-on-value-class attribute is missing on element", target);
      return;
    }
  }

  toggleVisibility(source) {
    const scope = source.dataset.toggleVisibilityOnValueScope;
    const visibility = !!source.value;

    this.targetElementsTargets.forEach((target) => {
      if (target.dataset.toggleVisibilityOnValueScope === scope) {
        const properties = target.dataset.toggleVisibilityOnValueClass.split(" ").map((str) => str.trim());

        if (visibility) {
          properties.forEach((property) => target.classList.remove(property));
        } else {
          properties.forEach((property) => target.classList.add(property));
          this.clearValue(target);
        }
      }
    });
  }

  clearValue(element) {
    const input = element.querySelector("input");
    if (input) {
      input.value = "";
    }
  }
}
