import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["start", "end"]

  connect() {
    if (!this.hasStartTarget || !this.hasEndTarget) return

    this.startTarget.addEventListener("change", this.setMinOnEndDate.bind(this))

    this.endTarget.addEventListener("focus", this.fillEndDateIfBlank.bind(this))
  }

  setMinOnEndDate(event) {
    const startValue = event.target.value
    if (startValue) {
      this.endTarget.min = startValue
    }
  }

  fillEndDateIfBlank() {
    if (!this.endTarget.value && this.startTarget.value) {
      this.endTarget.value = this.startTarget.value
      this.endTarget.min   = this.startTarget.value
    }
  }
}
